import { APP_PREFIX_PATH } from "./AppConfig"

// This is authenticated user /app-pages/index.js routes configuration
// All routes most be configured here
export const ROUTES = {
    DASHBOARD: `${APP_PREFIX_PATH}/dashboard`,
    AWS_DASHBOARD: `${APP_PREFIX_PATH}/awsDashboard`,
    AZURE_DASHBOARD: `${APP_PREFIX_PATH}/azureDashboard`,
    IAC_IMPORT: `${APP_PREFIX_PATH}/iacImport`,
    RESOURCE_FINDER: `${APP_PREFIX_PATH}/resourceFinder`,
    TF_EXPLORER: `${APP_PREFIX_PATH}/tfExplorer`,
    TF_REGISTRY: `${APP_PREFIX_PATH}/tfRegistry`,
    TF_MODULE_DETAILS: `${APP_PREFIX_PATH}/tfModule/:moduleId`,
    CREATE_TF_MODULE_WIZARD: `${APP_PREFIX_PATH}/createTFModuleWizard`,
    EDIT_TF_MODULE_WIZARD: `${APP_PREFIX_PATH}/editTFModuleWizard/:moduleId`,
    TEMPLATES: `${APP_PREFIX_PATH}/templates`,
    TEMPLATE: `${APP_PREFIX_PATH}/template/:id`,
    DRIFT_CENTER: `${APP_PREFIX_PATH}/driftCenter`,
    CONTROL_POLICIES: `${APP_PREFIX_PATH}/controlPolicies`,
    SECURITY_STANDARD: `${APP_PREFIX_PATH}/securityStandard/:id`,
    CONTROL_POLICY_GROUP: `${APP_PREFIX_PATH}/controlPolicyGroup/:controlPolicyGroupId`,
    POLICY_VIOLATIONS: `${APP_PREFIX_PATH}/policyViolations`,
    STACK: `${APP_PREFIX_PATH}/stack/:stackId`,
    DEPLOYMENT: `${APP_PREFIX_PATH}/deployment/:deploymentId`,
    PLAN: `${APP_PREFIX_PATH}/plan/:planId`,
    TASK: `${APP_PREFIX_PATH}/task/:taskId`,
    REMOTE_RUN: `${APP_PREFIX_PATH}/remoteRun/:runId`,
    STACKS: `${APP_PREFIX_PATH}/stacks`,
    CREATE_TEMPLATE_WIZARD: `${APP_PREFIX_PATH}/createTemplateWizard`,
    EDIT_TEMPLATE_WIZARD: `${APP_PREFIX_PATH}/editTemplateWizard/:templateId`,
    CREATE_BLUEPRINT_WIZARD: `${APP_PREFIX_PATH}/createBlueprintWizard`,
    EDIT_BLUEPRINT_WIZARD: `${APP_PREFIX_PATH}/editBlueprintWizard/:blueprintId`,
    CREATE_STACK_FROM_TEMPLATE_WIZARD: `${APP_PREFIX_PATH}/createStackFromTemplateWizard/:templateId`,
    CREATE_STACK_FROM_BLUEPRINT_WIZARD: `${APP_PREFIX_PATH}/createStackFromBlueprintWizard/:blueprintId`,
    CREATE_STACK_WIZARD: `${APP_PREFIX_PATH}/createStackWizard`,
    EDIT_STACK_WIZARD: `${APP_PREFIX_PATH}/editStackWizard/:stackId`,
    IMPORT_STACK_WIZARD: `${APP_PREFIX_PATH}/importStackWizard/:suggestedStackId`,
    IMPORT_TO_EXISTING_STACK_WIZARD: `${APP_PREFIX_PATH}/importToExistingStackWizard/:suggestedStackId`,
    IMPORT_BULK_STACKS_WIZARD: `${APP_PREFIX_PATH}/importBulkStacksWizard`,
    CREATE_NAMESPACE_WIZARD: `${APP_PREFIX_PATH}/createNamespaceWizard`,
    EDIT_NAMESPACE_WIZARD: `${APP_PREFIX_PATH}/editNamespaceWizard/:namespaceId`,
    NAMESPACES: `${APP_PREFIX_PATH}/namespaces`,
    NAMESPACE: `${APP_PREFIX_PATH}/namespace/:namespaceId`,
    USER: `${APP_PREFIX_PATH}/user/setting`,
    TEAM: `${APP_PREFIX_PATH}/team/:teamId`,
    ORGANIZATION: `${APP_PREFIX_PATH}/organization/setting`,
    PERSONAL_ACCESS_TOKEN: `${APP_PREFIX_PATH}/personalAccessToken/terraformLogin`,
    CREATE_MODEL_WIZARD: `${APP_PREFIX_PATH}/createModelWizard`,
    AUDIT: `${APP_PREFIX_PATH}/audit`,
    CREATE_CONTROL_POLICY_WIZARD: `${APP_PREFIX_PATH}/createControlPolicyWizard`,
    EDIT_CONTROL_POLICY_WIZARD: `${APP_PREFIX_PATH}/editControlPolicyWizard/:controlPolicyId`,
    CREATE_CONTROL_POLICY_GROUP_WIZARD: `${APP_PREFIX_PATH}/createControlPolicyGroupWizard`,
    EDIT_CONTROL_POLICY_GROUP_WIZARD: `${APP_PREFIX_PATH}/editControlPolicyGroupWizard/:controlPolicyGroupId`,
    CLOUD_EVENTS: `${APP_PREFIX_PATH}/cloudEvents`,
    ACTIVE_RUNS: `${APP_PREFIX_PATH}/activeRuns`,
    DOCS_FALLBACK: `${APP_PREFIX_PATH}/docsFallback`,
    REPORTS: `${APP_PREFIX_PATH}/reports`,
    CREATE_STACK_SET_WIZARD: `${APP_PREFIX_PATH}/createStackSetWizard`,
    // special pages
    POST_GITHUB_INSTALLATION: `${APP_PREFIX_PATH}/postGithubInstallation`,
}
