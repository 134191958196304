import { AUTH_TOKEN, USER, ORGANIZATION, AWS_ACCOUNT_ID, AWS_REGION_ID, LASTEST_NOTIFICATION_DATE, AZURE_SUBSCRIPTION_ID, AZURE_REGION_ID } from 'redux/constants/Auth';

const localStorageHelper = {}

localStorageHelper.getAwsAccountId = function () {
    let token = localStorage.getItem(AWS_ACCOUNT_ID);
    return token;
}

localStorageHelper.setAwsAccountId = function (awsAccountId) {
    localStorage.setItem(AWS_ACCOUNT_ID, awsAccountId);
}

localStorageHelper.getAwsRegionId = function () {
    let token = localStorage.getItem(AWS_REGION_ID);
    return token;
}

localStorageHelper.setAwsRegionId = function (awsRegionId) {
    localStorage.setItem(AWS_REGION_ID, awsRegionId);
}

localStorageHelper.getAzureSubscriptionId = function () {
    let token = localStorage.getItem(AZURE_SUBSCRIPTION_ID);
    return token;
}

localStorageHelper.setAzureSubscriptionId = function (azureSubscriptionId) {
    localStorage.setItem(AZURE_SUBSCRIPTION_ID, azureSubscriptionId);
}

localStorageHelper.getAzureRegionId = function () {
    let token = localStorage.getItem(AZURE_REGION_ID);
    return token;
}

localStorageHelper.setAzureRegionId = function (azureRegionId) {
    localStorage.setItem(AZURE_REGION_ID, azureRegionId);
}

localStorageHelper.getToken = function () {
    let token = localStorage.getItem(AUTH_TOKEN);
    return token;
}

localStorageHelper.getUser = function () {
    const user = localStorage.getItem(USER);
    return user;
}

localStorageHelper.overrideUserPersonalInformation = function (updatedUser) {
    localStorage.setItem(USER, JSON.stringify(updatedUser));
}

localStorageHelper.onSignIn = function (session) {
    localStorage.setItem(AUTH_TOKEN, session.token);

    if (session.user) {
        localStorage.setItem(USER, JSON.stringify(session.user));
    }

    if (session.organization) {
        localStorage.setItem(ORGANIZATION, JSON.stringify(session.organization));
    }
}

localStorageHelper.getOrganization = function () {
    return localStorage.getItem(ORGANIZATION);
}

localStorageHelper.getLatestNotificationDate = function () {
    const date = localStorage.getItem(LASTEST_NOTIFICATION_DATE);
    return date;
}

localStorageHelper.setLatestNotificationDate = function(utcDate) {
    localStorage.setItem(LASTEST_NOTIFICATION_DATE, utcDate);
}

localStorageHelper.onOrgChange = function (newSession) {
    localStorage.setItem(AUTH_TOKEN, newSession.token);
    localStorage.setItem(ORGANIZATION, JSON.stringify(newSession.organization));
    localStorage.removeItem(AWS_ACCOUNT_ID);
    localStorage.removeItem(AZURE_SUBSCRIPTION_ID);
}

localStorageHelper.onSignOut = function () {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER);
    localStorage.removeItem(ORGANIZATION);

    // TODO - delete after all users log out from the system and the local storage is clean
    localStorage.removeItem('account');
    localStorage.removeItem('account_id');
}

export default localStorageHelper;